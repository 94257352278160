import React, {useEffect, useState} from 'react';
import { configureStore, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Provider, useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

// Redux Slice for Chat
const chatSlice = createSlice({
    name: 'chat',
    initialState: { messages: [], status: 'idle', error: null },
    reducers: {
        addMessage: (state, action) => {
            state.messages.push(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase('chat/fetchResponse/pending', (state) => {
                state.status = 'loading';
            })
            .addCase('chat/fetchResponse/fulfilled', (state, action) => {
                state.status = 'idle';
                state.messages.push({ sender: 'bot', text: action.payload });
            })
            .addCase('chat/fetchResponse/rejected', (state) => {
                state.status = 'error';
                state.messages.push({ sender: 'bot', text: 'Sorry, I am having some trouble.' });
            });
    },
});

const { actions, reducer } = chatSlice;
const { addMessage } = actions;
const instance = axios.create({
    baseURL: 'https://your-api-domain.com/',
    timeout: 1000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // ... any other headers you want to set
    },
});

const initializeChat = createAsyncThunk('chat/initialize', async (thunkAPI) => {
    const response = await axios.post('https://docduckbe.greentest.co.kr/api/initialize');
    return response.data;
});

const fetchResponse = createAsyncThunk('chat/fetchResponse', async (payload, thunkAPI) => {
    const credential = thunkAPI.getState().chat.credential;
    const params = new URLSearchParams();
    params.append('message', payload);
    params.append('credential', 0);

    const response = await axios.post('https://docduckbe.greentest.co.kr/api/prompt', params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
    return response.data.message;
});

const store = configureStore({
    reducer: { chat: reducer },
});

const App = () => {
    const dispatch = useDispatch();
    const messages = useSelector((state) => state.chat.messages);
    const status = useSelector((state) => state.chat.status); // Add this line
    let isInitialize = 0;

    const [input, setInput] = useState('');

    useEffect(() => {
        if(isInitialize === 0) {
            isInitialize = 1;
        }

        if(isInitialize === 1) {
            dispatch(initializeChat()).then((response) => {
                if (response.payload) {
                    dispatch(addMessage({ sender: 'bot', text: response.payload.message }));
                }
            });
            isInitialize = 2;
        }
    }, [dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addMessage({ sender: 'user', text: input }));
        dispatch(fetchResponse(input));
        setInput('');
    };

    const styles = {
        container: {
            width: '90%',
            maxWidth: '600px',
            margin: '50px auto',
            fontFamily: 'Arial, sans-serif'
        },
        chatBox: {
            border: '1px solid gray',
            padding: '20px',
            overflowY: 'scroll',
            height: '400px'
        },
        inputField: {
            width: '70%',
            padding: '10px',
            marginRight: '10px'
        },
        sendButton: {
            padding: '10px'
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.chatBox}>
                {messages.map((msg, idx) => (
                    <div key={idx} style={{ textAlign: msg.sender === 'user' ? 'right' : 'left' }}>
                        <div style={{ borderRadius: '10px', padding: '10px', margin: '5px', display: 'inline-block', backgroundColor: msg.sender === 'user' ? '#007AFF' : '#E5E5EA', color: msg.sender === 'user' ? 'white' : 'black' }}>
                            {msg.text}
                        </div>
                    </div>
                ))}
            </div>
            <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
                <input type="text" value={input} onChange={(e) => setInput(e.target.value)} style={styles.inputField} />
                <button type="submit" style={styles.sendButton}>Send</button>
            </form>
        </div>
    );
};

const ChatApp = () => {
    return (
        <Provider store={store}>
            <App />
        </Provider>
    );
};

export default ChatApp;
